import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const LandingPage = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/c");
    }
  });
  return (
    <div>
      <h1>Landing page</h1>
      <button
        onClick={() => {
          loginWithRedirect();
        }}
        className="bg-[#004DB5] text-white px-2 sm:px-4 py-2 rounded-lg hover:bg-blue-600 text-sm sm:text-base flex-1"
      >
        Login
      </button>
    </div>
  );
};
