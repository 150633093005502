import React, { useState } from 'react';
import { Book, Briefcase, Database, FileText, X } from 'lucide-react';
import KnowledgeGraphExplorer from './KnowledgeGraphExplorer';
import { useAgentContext } from '../context/AgentContext';

const AgentOverview: React.FC = () => {
  const [isExplorerOpen, setIsExplorerOpen] = useState(false);
  const { selectedPersona } = useAgentContext();

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-4" style={{ color: '#004DB5' }}>
        {selectedPersona.name} Overview
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="p-4 rounded-lg" style={{ backgroundColor: '#D8E9FF' }}>
          <div className="flex items-center mb-2">
            <Book className="mr-2" size={24} style={{ color: '#004DB5' }} />
            <h3 className="text-lg font-semibold" style={{ color: '#555555' }}>
              Knowledge Domains
            </h3>
          </div>
          <ul className="list-disc list-inside">
            {selectedPersona.knowledgeDomains.map((domain, index) => (
              <li key={index} className="text-sm" style={{ color: '#000000' }}>
                {domain}
              </li>
            ))}
          </ul>
        </div>

        <div className="p-4 rounded-lg" style={{ backgroundColor: '#D8E9FF' }}>
          <div className="flex items-center mb-2">
            <Database className="mr-2" size={24} style={{ color: '#004DB5' }} />
            <h3 className="text-lg font-semibold" style={{ color: '#555555' }}>
              Total Knowledge Nodes
            </h3>
          </div>
          <p className="text-2xl font-bold" style={{ color: '#000000' }}>
            {selectedPersona.totalNodes.toLocaleString()}
          </p>
        </div>

        <div className="p-4 rounded-lg" style={{ backgroundColor: '#D8E9FF' }}>
          <div className="flex items-center mb-2">
            <Briefcase className="mr-2" size={24} style={{ color: '#004DB5' }} />
            <h3 className="text-lg font-semibold" style={{ color: '#555555' }}>
              Recent Updates
            </h3>
          </div>
          <p className="text-2xl font-bold" style={{ color: '#000000' }}>
            {selectedPersona.recentUpdates}
          </p>
          <p className="text-sm" style={{ color: '#B2B2B2' }}>in the last 7 days</p>
        </div>

        <div className="p-4 rounded-lg" style={{ backgroundColor: '#D8E9FF' }}>
          <div className="flex items-center mb-2">
            <FileText className="mr-2" size={24} style={{ color: '#004DB5' }} />
            <h3 className="text-lg font-semibold" style={{ color: '#555555' }}>
              Custom Documents
            </h3>
          </div>
          <p className="text-2xl font-bold" style={{ color: '#000000' }}>
            {selectedPersona.customDocuments}
          </p>
          <p className="text-sm" style={{ color: '#B2B2B2' }}>uploaded by users</p>
        </div>
      </div>

      <div className="mt-6">
        <button
          className="text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          style={{ backgroundColor: '#004DB5' }}
          onClick={() => setIsExplorerOpen(true)}
        >
          Explore Knowledge Graph
        </button>
      </div>

      {isExplorerOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-11/12 h-5/6 flex flex-col">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold" style={{ color: '#004DB5' }}>
                Knowledge Graph Explorer
              </h3>
              <button
                onClick={() => setIsExplorerOpen(false)}
                className="hover:text-gray-700"
                style={{ color: '#7D7D7D' }}
              >
                <X size={24} />
              </button>
            </div>
            <div className="flex-grow overflow-hidden">
              <KnowledgeGraphExplorer />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentOverview;