import React, { useState } from "react";
import { Search, Upload } from "lucide-react";
import Tooltip from "../ui/Tooltip";
import { DocumentList } from "./DocumentList";

interface Document {
  id: string;
  name: string;
  type: string;
  uploadedBy: string;
  uploadDate: Date;
  size: number;
}

const DocumentLibrary: React.FC = () => {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<{
    [key: string]: number;
  }>({});
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null
  );
  const [streamingResults, setStreamingResults] = useState<string[]>([]);

  const [documents, setDocuments] = useState<Document[]>([]);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (!files) return;

    setUploading(true);
    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append("files", file);
    });
    formData.append("agent_id", "default_agent_id");
    formData.append("user_id", "default_user_id");

    try {
      setStreamingResults((prev) => [""]);
      fetch(
        "https://document-ingestion-test.brainchain.cloud/api/v1/knowledge/files/upload",
        {
          method: "POST",
          body: formData,
        }
      )
        .then((response) => {
          // @ts-ignore
          const reader = response.body.getReader();
          // read() returns a promise that resolves when a value has been received
          // @ts-ignore
          reader.read().then(function pump({ done, value }) {
            if (done) {
              // Do something with last chunk of data then exit reader
              return;
            }
            // Otherwise do something here to process current chunk
            // console.log(new TextDecoder().decode(value));
            setStreamingResults((prev) => [
              ...prev,
              new TextDecoder().decode(value),
            ]);
            // Read some more, and call this function again
            return reader.read().then(pump);
          });
        })
        .catch((err) => console.error(err));

      // Simulate upload progress and streaming results
      for (let i = 0; i <= 100; i += 10) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        setUploadProgress((prev) =>
          Object.fromEntries(Array.from(files).map((file) => [file.name, i]))
        );
      }

      const newDocuments = Array.from(files).map((file, index) => ({
        id: (documents.length + index + 1).toString(),
        name: file.name,
        type: file.name.split(".").pop() || "unknown",
        uploadedBy: "Current User",
        uploadDate: new Date(),
        size: file.size,
      }));

      // setDocuments((prev) => [...prev, ...newDocuments]);
      // setStreamingResults((prev) => [
      //   ...prev,
      //   "Upload complete. Documents processed successfully.",
      // ]);
    } catch (error) {
      console.error("Error uploading files:", error);
      setStreamingResults((prev) => [...prev, "Error occurred during upload."]);
    } finally {
      setUploading(false);
      setUploadProgress({});
    }
  };

  const generateVectorIndex = async () => {
    try {
      // TODO: Implement actual API call
      // await fetch('/document_ingestion/api/v1/knowledge/rag/generate_vector_index', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({
      //     index_name: 'document_index',
      //     node_label: 'Document',
      //     embedding_property: 'embedding',
      //     vector_dimensions: 768,
      //     similarity_function: 'cosine'
      //   })
      // });
      console.log("Vector index generated");
      setStreamingResults((prev) => [
        ...prev,
        "Vector index generated successfully.",
      ]);
    } catch (error) {
      console.error("Error generating vector index:", error);
      setStreamingResults((prev) => [
        ...prev,
        "Error occurred while generating vector index.",
      ]);
    }
  };

  const reindexDocumentChunks = async () => {
    try {
      // TODO: Implement actual API call
      // const response = await fetch('/document_ingestion/api/v1/knowledge/reindex_document_chunks', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ batch_size: 100 })
      // });
      // const reader = response.body.getReader();
      // while (true) {
      //   const { done, value } = await reader.read();
      //   if (done) break;
      //   console.log(new TextDecoder().decode(value));
      // }
      console.log("Document chunks reindexed");
      setStreamingResults((prev) => [
        ...prev,
        "Document chunks reindexed successfully.",
      ]);
    } catch (error) {
      // Add a modal for error handling or display a toast notification
      // console.error("Error reindexing document chunks:", error);
      // setStreamingResults((prev) => [
      //   ...prev,
      //   "Error occurred while reindexing document chunks.",
      // ]);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-4 sm:p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6" style={{ color: "#004DB5" }}>
        Document Library
      </h2>

      <div className="mb-6 flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <div className="w-full sm:w-auto mb-4 sm:mb-0">
          <div className="relative w-full sm:w-64 mb-4 sm:mb-0">
            <input
              type="text"
              placeholder="Search documents..."
              // value={searchTerm}
              // onChange={(e) => handleSearch(e.target.value)}
              className="w-full p-2 pl-10 border rounded-lg shadow-sm"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row w-full sm:w-auto space-y-2 sm:space-y-0 sm:space-x-2">
          <Tooltip content="Upload new documents to the library">
            <label className="cursor-pointer bg-[#004DB5] text-white px-4 py-2 rounded-lg hover:bg-blue-600 flex items-center justify-center w-full sm:w-auto">
              <Upload className="inline mr-2 h-5 w-5" />
              Upload
              <input
                type="file"
                onChange={handleFileUpload}
                className="hidden"
                multiple
              />
            </label>
          </Tooltip>
          <div className="flex justify-between sm:justify-start space-x-2 w-full sm:w-auto">
            <Tooltip content="Generate a vector index for efficient similarity search across all documents. This improves search speed and accuracy.">
              <button
                onClick={generateVectorIndex}
                className="bg-[#004DB5] text-white px-2 sm:px-4 py-2 rounded-lg hover:bg-blue-600 text-sm sm:text-base flex-1 sm:flex-none"
              >
                Generate Index
              </button>
            </Tooltip>
            <Tooltip content="Reprocess and update the embeddings for all document chunks. Use this after significant changes to improve search quality.">
              <button
                onClick={reindexDocumentChunks}
                className="bg-[#004DB5] text-white px-2 sm:px-4 py-2 rounded-lg hover:bg-blue-600 text-sm sm:text-base flex-1 sm:flex-none"
              >
                Reindex Chunks
              </button>
            </Tooltip>
          </div>
        </div>
      </div>

      {uploading && (
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Upload Progress</h3>
          {Object.entries(uploadProgress).map(([fileName, progress]) => (
            <div key={fileName} className="mb-2">
              <div className="text-sm">{fileName}</div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-[#004DB5] h-2.5 rounded-full"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Upload Status</h3>
        <div className="bg-gray-100 p-4 rounded-lg max-h-40 overflow-y-auto">
          {streamingResults.map((result, index) => (
            <div key={index} className="text-sm mb-1">
              {result}
            </div>
          ))}
        </div>
      </div>
      <DocumentList />
    </div>
  );
};

export default DocumentLibrary;
