import React, { useState } from 'react';
import { Plus, Check, X, Wrench } from 'lucide-react';

// Define the tool item structure
interface ToolItem {
  id: string;
  name: string;
  description: string;
  isActive: boolean;
  isBuiltIn: boolean;
  isForUser: boolean;
}

// Define the new tool form structure
interface NewToolForm {
  name: string;
  description: string;
  apiKey?: string; // For complex integrations
  productName?: string; // For complex integrations
  isComplex: boolean;
}

const ToolManagement: React.FC = () => {
  // Initial list of tools with mock integrations for user tools
  const [tools, setTools] = useState<ToolItem[]>([
    { id: '1', name: 'Google Sheets', description: 'Sync data with Google Sheets', isActive: true, isBuiltIn: true, isForUser: true },
    { id: '2', name: 'Slack', description: 'Send notifications to Slack channels', isActive: true, isBuiltIn: true, isForUser: true },
    { id: '3', name: 'Salesforce', description: 'Manage CRM data and operations', isActive: false, isBuiltIn: true, isForUser: true },
    { id: '4', name: 'Code Editing', description: 'Modify and edit code snippets', isActive: true, isBuiltIn: true, isForUser: false }, // Agent-only
    { id: '5', name: 'Jupyter Console', description: 'Run Python scripts in a Jupyter-like environment', isActive: true, isBuiltIn: true, isForUser: false }, // Agent-only
    { id: '6', name: 'Terminal Emulator', description: 'Execute shell commands in a virtual terminal', isActive: false, isBuiltIn: true, isForUser: false }, // Agent-only
    { id: '7', name: 'Web Browsing', description: 'Enable agent to browse the web autonomously', isActive: false, isBuiltIn: true, isForUser: false }, // Agent-only
    { id: '8', name: 'Custom API', description: 'User-defined API integration', isActive: true, isBuiltIn: false, isForUser: true },
  ]);

  // State for adding new tools
  const [newTool, setNewTool] = useState<NewToolForm>({
    name: '',
    description: '',
    isComplex: false,
    apiKey: '',
    productName: '',
  });

  // Toggle the active status of a tool
  const toggleToolStatus = (id: string) => {
    setTools(tools.map(tool =>
      tool.id === id ? { ...tool, isActive: !tool.isActive } : tool
    ));
  };

  // Add a new tool to the list
  const addNewTool = () => {
    if (newTool.name && newTool.description) {
      setTools([...tools, {
        id: (tools.length + 1).toString(),
        name: newTool.name,
        description: newTool.description,
        isActive: true,
        isBuiltIn: false,
        isForUser: true, // User-defined tools are for the user
      }]);
      // Reset the form
      setNewTool({ name: '', description: '', isComplex: false, apiKey: '', productName: '' });
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-4" style={{ color: '#004DB5' }}>
        Tool Management
      </h2>

      {/* Separate user-facing tools from agent-only tools */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2" style={{ color: '#555555' }}>
          User Tools (Integrations)
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {tools.filter(tool => tool.isForUser).map((tool) => (
            <div key={tool.id} className="flex items-center justify-between bg-gray-50 rounded-lg p-4 shadow">
              <div>
                <h3 className="text-lg font-semibold">{tool.name}</h3>
                <p className="text-sm text-gray-600">{tool.description}</p>
                {tool.isBuiltIn && <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full">Built-in</span>}
              </div>
              <div className="flex items-center">
                <span className={`mr-2 ${tool.isActive ? 'text-[#004DB5]' : 'text-[#B2B2B2]'}`}>
                  {tool.isActive ? 'Active' : 'Inactive'}
                </span>
                <button
                  onClick={() => toggleToolStatus(tool.id)}
                  className={`p-2 rounded-full ${tool.isActive ? 'bg-[#004DB5] text-white' : 'bg-[#F5F5F5] text-[#B2B2B2]'}`}
                >
                  {tool.isActive ? <Check size={20} /> : <X size={20} />}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Agent-only tools */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2" style={{ color: '#555555' }}>
          Agent-Only Tools
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {tools.filter(tool => !tool.isForUser).map((tool) => (
            <div key={tool.id} className="flex items-center justify-between bg-gray-50 rounded-lg p-4 shadow">
              <div>
                <h3 className="text-lg font-semibold">{tool.name}</h3>
                <p className="text-sm text-gray-600">{tool.description}</p>
                {tool.isBuiltIn && <span className="text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded-full">Built-in</span>}
              </div>
              <div className="flex items-center">
                <span className={`mr-2 ${tool.isActive ? 'text-[#004DB5]' : 'text-[#B2B2B2]'}`}>
                  {tool.isActive ? 'Active' : 'Inactive'}
                </span>
                <button
                  onClick={() => toggleToolStatus(tool.id)}
                  className={`p-2 rounded-full ${tool.isActive ? 'bg-[#004DB5] text-white' : 'bg-[#F5F5F5] text-[#B2B2B2]'}`}
                >
                  {tool.isActive ? <Check size={20} /> : <X size={20} />}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Add new tool form */}
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-2" style={{ color: '#555555' }}>
          Add New Tool
        </h3>
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0">
            <input
              type="text"
              placeholder="Tool Name"
              value={newTool.name}
              onChange={(e) => setNewTool({ ...newTool, name: e.target.value })}
              className="w-full sm:w-1/2 border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              placeholder="Tool Description"
              value={newTool.description}
              onChange={(e) => setNewTool({ ...newTool, description: e.target.value })}
              className="w-full sm:w-1/2 border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Checkbox for complex tools */}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="isComplex"
              checked={newTool.isComplex}
              onChange={(e) => setNewTool({ ...newTool, isComplex: e.target.checked })}
              className="mr-2"
            />
            <label htmlFor="isComplex" className="text-sm text-gray-600">Is this a complex tool (requires API key)?</label>
          </div>

          {/* Display fields for complex tool integrations */}
          {newTool.isComplex && (
            <div className="flex flex-col space-y-2">
              <input
                type="text"
                placeholder="Product Name"
                value={newTool.productName}
                onChange={(e) => setNewTool({ ...newTool, productName: e.target.value })}
                className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                placeholder="API Key"
                value={newTool.apiKey}
                onChange={(e) => setNewTool({ ...newTool, apiKey: e.target.value })}
                className="border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          )}

          {/* Add button */}
          <button
            onClick={addNewTool}
            className="self-start bg-[#004DB5] text-white rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <Plus size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ToolManagement;