import React, { createContext, useState, ReactNode, useContext } from 'react';

export interface Node {
  id: string;
  name: string;
  group: 'agent' | 'domain' | 'user-added' | 'predefined';
  val: number;
}

export interface AgentPersona {
  id: string;
  name: string;
  description: string;
  nodes: Node[];
  knowledgeDomains: string[];
  totalNodes: number;
  recentUpdates: number;
  customDocuments: number;
}

// Separate user uploads for each agent
const createUserUploads = (agentId: string): Node[] => [
  { id: `${agentId}_user_uploads`, name: 'User Uploads', group: 'user-added', val: 15 },
  { id: `${agentId}_user_uploads-doc1`, name: 'Company Policy', group: 'user-added', val: 5 },
  { id: `${agentId}_user_uploads-doc2`, name: 'Supplier List', group: 'user-added', val: 5 },
  { id: `${agentId}_user_uploads-doc3`, name: 'Market Report 2023', group: 'user-added', val: 5 },
];

// Default persona (General AI)
const defaultPersona: AgentPersona = {
  id: 'default',
  name: 'Default Agent',
  description: 'General-purpose AI assistant',
  nodes: [
    { id: 'default', name: 'Default Agent', group: 'agent', val: 30 },
    { id: 'general_knowledge', name: 'General Knowledge', group: 'domain', val: 20 },
    { id: 'problem_solving', name: 'Problem Solving', group: 'domain', val: 20 },
    ...createUserUploads('default'), // User Uploads for default agent
  ],
  knowledgeDomains: ['General Knowledge', 'Problem Solving'],
  totalNodes: 1000,
  recentUpdates: 50,
  customDocuments: 3,
};

// Procurement persona
const procurementPersona: AgentPersona = {
  id: 'procurement',
  name: 'Procurement Analyst',
  description: 'Specialized in supply chain and procurement',
  nodes: [
    { id: 'procurement', name: 'Procurement Analyst', group: 'agent', val: 30 },
    { id: 'supply_chain_procurement', name: 'Supply Chain', group: 'domain', val: 20 },
    { id: 'vendor_management', name: 'Vendor Management', group: 'domain', val: 20 },
    ...createUserUploads('procurement'), // User Uploads for procurement agent
  ],
  knowledgeDomains: ['Supply Chain', 'Vendor Management'],
  totalNodes: 1500,
  recentUpdates: 75,
  customDocuments: 3,
};

// Finance persona
const financePersona: AgentPersona = {
  id: 'finance',
  name: 'Financial Advisor',
  description: 'Expert in financial analysis and planning',
  nodes: [
    { id: 'finance', name: 'Financial Advisor', group: 'agent', val: 30 },
    { id: 'investment_strategies', name: 'Investment Strategies', group: 'domain', val: 20 },
    { id: 'tax_planning', name: 'Tax Planning', group: 'domain', val: 20 },
    ...createUserUploads('finance'), // User Uploads for finance agent
  ],
  knowledgeDomains: ['Investment Strategies', 'Tax Planning'],
  totalNodes: 1200,
  recentUpdates: 60,
  customDocuments: 3,
};

// Custom Broker persona
const customBrokerPersona: AgentPersona = {
  id: 'custom-broker',
  name: 'Custom Broker',
  description: 'Specialized in customs regulations and international trade',
  nodes: [
    { id: 'custom-broker', name: 'Custom Broker', group: 'agent', val: 30 },
    { id: 'international_trade_laws', name: 'International Trade Laws', group: 'domain', val: 20 },
    { id: 'tariff_classification', name: 'Tariff Classification', group: 'domain', val: 20 },
    ...createUserUploads('custom-broker'), // User Uploads for custom broker agent
  ],
  knowledgeDomains: ['International Trade Laws', 'Tariff Classification'],
  totalNodes: 1300,
  recentUpdates: 70,
  customDocuments: 3,
};

// Export personas for usage
export const personas = [defaultPersona, procurementPersona, financePersona, customBrokerPersona];

interface AgentContextType {
  selectedPersona: AgentPersona;
  setSelectedPersona: React.Dispatch<React.SetStateAction<AgentPersona>>;
  personas: AgentPersona[];
}

const AgentContext = createContext<AgentContextType | undefined>(undefined);

export const AgentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedPersona, setSelectedPersona] = useState<AgentPersona>(defaultPersona);

  return (
    <AgentContext.Provider value={{ selectedPersona, setSelectedPersona, personas }}>
      {children}
    </AgentContext.Provider>
  );
};

export const useAgentContext = () => {
  const context = useContext(AgentContext);
  if (context === undefined) {
    throw new Error('useAgentContext must be used within an AgentProvider');
  }
  return context;
};
