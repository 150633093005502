import React, { useState, useEffect } from 'react';
import { Send, Paperclip, FileText, Image, BarChart, PlusCircle, ChevronLeft, ChevronRight } from 'lucide-react';

interface Message {
  id: number;
  content: string | React.ReactNode;
  sender: 'user' | 'bot';
  timestamp: Date;
  type: 'text' | 'file' | 'image' | 'chart';
}

interface Chat {
  id: number;
  title: string;
  messages: Message[];
}

const mockChats: Chat[] = [
    {
      id: 1,
      title: 'Customs Classification Help',
      messages: [
        { id: 1, content: "Hello, I need help with a customs classification for a new product we're importing.", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 30), type: 'text' },
        { id: 2, content: "Certainly! I'd be happy to help you with the customs classification. Can you provide more details about the product?", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 28), type: 'text' },
        { id: 3, content: "It's a new type of smart watch. It's made primarily of plastic and electronic components, with a small amount of precious metals.", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 27), type: 'text' },
        { id: 4, content: "Thanks! Based on your description, the smart watch would likely fall under HS code 8517.62, but I need to review the specifications.", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 25), type: 'text' },
        { id: 5, content: <div className="flex items-center"><FileText className="mr-2" />SmartWatch_Specifications.pdf</div>, sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 24), type: 'file' },
        { id: 6, content: "Thank you for the file. I'll analyze the details and get back to you.", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 23), type: 'text' },
        { id: 7, content: "Sure, take your time.", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 22), type: 'text' },
        { id: 8, content: "I've analyzed it. Your smart watch indeed falls under HS code 8517.62. Would you like a tariff rate breakdown?", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 21), type: 'text' },
        { id: 9, content: "Yes, please provide a chart of the rates.", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 20), type: 'text' },
        { id: 10, content: <div className="w-full h-64 bg-gray-200 flex items-center justify-center"><BarChart size={48} /><span className="ml-2">Tariff Rates for HS Code 8517.62</span></div>, sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 19), type: 'chart' },
        { id: 11, content: "Here’s the chart for major countries. Do you have specific markets in mind?", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 18), type: 'text' },
        { id: 12, content: "Yes, we're targeting the US and EU markets. Could you provide details for these regions?", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 17), type: 'text' },
        { id: 13, content: "For the US, there's a 0% tariff on smart watches under this HS code. For the EU, you'll need to comply with CE marking and other standards.", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 16), type: 'text' },
        { id: 14, content: "Could you give me a checklist for EU export requirements?", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 15), type: 'text' },
        { id: 15, content: "Certainly! I'll prepare a detailed compliance checklist for you.", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 14), type: 'text' },
      ],
    },
    {
      id: 2,
      title: 'HS Code Tariff Rates',
      messages: [
        { id: 1, content: "Can you show me the tariff rates for HS code 8517.62?", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 60), type: 'text' },
        { id: 2, content: "Of course. Let me fetch the data for you.", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 58), type: 'text' },
        { id: 3, content: <div className="w-full h-64 bg-gray-200 flex items-center justify-center"><BarChart size={48} /><span className="ml-2">Tariff Rates for HS Code 8517.62</span></div>, sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 57), type: 'chart' },
        { id: 4, content: "Here are the tariff rates across key markets. Which one would you like to focus on?", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 56), type: 'text' },
        { id: 5, content: "Could you provide details for the EU and Japan?", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 55), type: 'text' },
        { id: 6, content: "Certainly. The EU has a 0% tariff on this product. Japan has a 5% tariff.", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 54), type: 'text' },
        { id: 7, content: "What are the import restrictions for these regions?", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 53), type: 'text' },
        { id: 8, content: "The EU requires CE marking and compliance with RoHS standards. Japan has specific labeling requirements for electronics.", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 52), type: 'text' },
        { id: 9, content: "Could you summarize these in a document?", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 51), type: 'text' },
        { id: 10, content: <div className="flex items-center"><FileText className="mr-2" />EU_Japan_Import_Restrictions.pdf</div>, sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 50), type: 'file' },
        { id: 11, content: "Thanks, that’s helpful. Any updates on US tariff rates?", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 49), type: 'text' },
        { id: 12, content: "Currently, the US has a 0% tariff for this HS code. There are no significant updates.", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 48), type: 'text' },
        { id: 13, content: "Good to know. Do I need to consider anything else when exporting to the US?", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 47), type: 'text' },
        { id: 14, content: "Just ensure compliance with FCC regulations for electronic products. Would you like more details?", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 46), type: 'text' },
        { id: 15, content: "Yes, please send those details.", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 45), type: 'text' },
      ],
    },
    {
      id: 3,
      title: 'EU Export Regulations',
      messages: [
        { id: 1, content: "We're exporting to the EU, can you provide the regulations?", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 90), type: 'text' },
        { id: 2, content: "Certainly! For the EU, smart watches must comply with CE marking, RoHS, and WEEE directives.", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 88), type: 'text' },
        { id: 3, content: "What are the specific requirements for CE marking?", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 87), type: 'text' },
        { id: 4, content: "CE marking requires compliance with health, safety, and environmental standards. I'll send you a checklist.", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 85), type: 'text' },
        { id: 5, content: <div className="flex items-center"><FileText className="mr-2" />CE_Marking_Checklist.pdf</div>, sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 84), type: 'file' },
        { id: 6, content: "Thanks! Could you also help with RoHS compliance?", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 83), type: 'text' },
        { id: 7, content: "RoHS compliance ensures restricted substances are below certain levels. I'll send the guide.", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 82), type: 'text' },
        { id: 8, content: <div className="flex items-center"><FileText className="mr-2" />RoHS_Compliance_Guide.pdf</div>, sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 81), type: 'file' },
        { id: 9, content: "Great! What about WEEE?", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 80), type: 'text' },
        { id: 10, content: "WEEE requires proper disposal of electronic waste. I'll prepare a document on how to comply.", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 79), type: 'text' },
        { id: 11, content: <div className="flex items-center"><FileText className="mr-2" />WEEE_Disposal_Guide.pdf</div>, sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 78), type: 'file' },
        { id: 12, content: "Thanks, you're covering everything!", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 77), type: 'text' },
        { id: 13, content: "Happy to help. Any specific concerns for the EU market?", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 76), type: 'text' },
        { id: 14, content: "Do we need to label products differently for the EU?", sender: 'user', timestamp: new Date(Date.now() - 1000 * 60 * 75), type: 'text' },
        { id: 15, content: "Yes, you'll need proper labeling in line with CE and WEEE requirements. I'll generate a labeling guide for you.", sender: 'bot', timestamp: new Date(Date.now() - 1000 * 60 * 74), type: 'text' },
      ],
    },
  ];
  

  const ChatInterface: React.FC = () => {
    const [chats, setChats] = useState<Chat[]>(mockChats);
    const [currentChat, setCurrentChat] = useState<Chat>(mockChats[0]);
    const [messages, setMessages] = useState<Message[]>(currentChat.messages);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
    useEffect(() => {
      const messageList = document.getElementById('message-list');
      if (messageList) {
        messageList.scrollTop = messageList.scrollHeight;
      }
    }, [messages]);
  
    const handleSend = () => {
      if (input.trim()) {
        const newMessage: Message = {
          id: Date.now(),
          content: input.trim(),
          sender: 'user',
          timestamp: new Date(),
          type: 'text',
        };
        setMessages([...messages, newMessage]);
        setInput('');
        setIsLoading(true);
  
        setTimeout(() => {
          const botResponse: Message = {
            id: Date.now(),
            content: "I'm processing your request. This is a placeholder response.",
            sender: 'bot',
            timestamp: new Date(),
            type: 'text',
          };
          setMessages(prevMessages => [...prevMessages, botResponse]);
          setIsLoading(false);
        }, 1500);
      }
    };
  
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const uploadMessage: Message = {
          id: Date.now(),
          content: <div className="flex items-center"><FileText className="mr-2" />{file.name}</div>,
          sender: 'user',
          timestamp: new Date(),
          type: 'file',
        };
        setMessages([...messages, uploadMessage]);
      }
    };
  
    const handleChatSelect = (chat: Chat) => {
      setCurrentChat(chat);
      setMessages(chat.messages);
      setIsSidebarOpen(false);
    };
  
    const handleNewChat = () => {
      const newChat: Chat = {
        id: Date.now(),
        title: 'New Chat',
        messages: [],
      };
      setChats([newChat, ...chats]);
      setCurrentChat(newChat);
      setMessages([]);
      setIsSidebarOpen(false);
    };
  
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  
    return (
      <div className="flex h-[calc(100vh-8rem)] relative">
        {/* Sidebar for previous chats */}
        <div className={`absolute md:relative w-3/4 md:w-1/4 bg-gray-100 p-4 rounded-lg shadow-lg h-full transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 z-10`}>
          <div className="mb-4 flex justify-between items-center">
            <button
              onClick={handleNewChat}
              className="flex items-center bg-[#004DB5] text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
            >
              <PlusCircle size={20} className="mr-2" />
              New Chat
            </button>
            <button
              onClick={toggleSidebar}
              className="md:hidden text-[#004DB5]"
            >
              <ChevronLeft size={24} />
            </button>
          </div>
          <div className="space-y-4 overflow-y-auto" style={{ maxHeight: 'calc(100% - 4rem)' }}>
            {chats.map((chat) => (
              <div
                key={chat.id}
                onClick={() => handleChatSelect(chat)}
                className={`p-3 rounded-lg cursor-pointer ${chat.id === currentChat.id ? 'bg-[#004DB5] text-white' : 'bg-white text-black'}`}
              >
                <h3 className="font-bold">{chat.title}</h3>
                <p className="text-sm">{chat.messages[chat.messages.length - 1]?.content?.toString().slice(0, 20)}...</p>
              </div>
            ))}
          </div>
        </div>
  
        {/* Main chat interface */}
        <div className="w-full md:w-3/4 bg-white rounded-lg shadow-lg p-4 flex flex-col">
          {/* Mobile sidebar toggle button */}
          <button
            onClick={toggleSidebar}
            className="md:hidden text-[#004DB5] mb-4 self-start"
          >
            <ChevronRight size={24} />
          </button>
  
          <div className="flex-1 overflow-y-auto mb-4 space-y-4" id="message-list">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                <div className={`max-w-[70%] p-3 rounded-lg ${
                  message.sender === 'user' ? 'bg-[#D8E9FF] text-[#000000]' : 'bg-[#F5F5F5] text-[#000000]'
                }`}>
                  {message.type === 'text' && <p>{message.content}</p>}
                  {message.type === 'file' && message.content}
                  {message.type === 'image' && <div className="w-full h-40 bg-gray-200 flex items-center justify-center"><Image size={24} /></div>}
                  {message.type === 'chart' && message.content}
                  <span className="text-xs" style={{ color: '#7D7D7D' }}>
                    {message.timestamp.toLocaleTimeString()}
                  </span>
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex justify-start">
                <div className="bg-[#F5F5F5] text-[#000000] p-3 rounded-lg">
                  <p>Typing...</p>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center space-x-2">
            <label htmlFor="file-upload" className="cursor-pointer">
              <Paperclip className="text-[#B2B2B2] hover:text-[#004DB5]" />
              <input
                id="file-upload"
                type="file"
                className="hidden"
                onChange={handleFileUpload}
              />
            </label>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSend()}
              className="flex-1 border rounded-lg px-4 py-2 focus:outline-none focus:ring-2"
              placeholder="Type your message..."
              style={{ color: '#B2B2B2', borderColor: '#004DB5' }}
            />
            <button
              onClick={handleSend}
              className="bg-[#004DB5] text-white rounded-lg p-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default ChatInterface;
