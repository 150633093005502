import React, { useState } from 'react';
import { Info, HelpCircle } from 'lucide-react';
import Tooltip from '../ui/Tooltip';

interface AgentConfig {
  knowledgeSources: {
    internalDocuments: boolean;
    externalAPIs: boolean;
    web: boolean;
  };
  behaviorSettings: {
    formality: number;
    recommendationStyle: 'cautious' | 'balanced' | 'proactive';
    autonomyLevel: number;
  };
  interactionPreferences: {
    notificationFrequency: 'minimal' | 'moderate' | 'frequent';
    responseFormat: 'concise' | 'detailed' | 'visual';
  };
  learningPreferences: {
    learningRate: 'conservative' | 'moderate' | 'aggressive';
    focusAreas: string[];
  };
}

const defaultConfig: AgentConfig = {
  knowledgeSources: {
    internalDocuments: true,
    externalAPIs: false,
    web: true,
  },
  behaviorSettings: {
    formality: 50,
    recommendationStyle: 'balanced',
    autonomyLevel: 50,
  },
  interactionPreferences: {
    notificationFrequency: 'moderate',
    responseFormat: 'detailed',
  },
  learningPreferences: {
    learningRate: 'moderate',
    focusAreas: [],
  },
};

// Agents available (mocked for now)
const agents = [
  { id: 'customsBroker', name: 'Customs Broker' },
  { id: 'procurementAnalyst', name: 'Procurement Analyst' },
  { id: 'logisticsManager', name: 'Logistics Manager' },
];

const AgentConfigurationInterface: React.FC = () => {
  const [selectedAgent, setSelectedAgent] = useState(agents[0].id);
  const [agentConfigs, setAgentConfigs] = useState<Record<string, AgentConfig>>({
    customsBroker: defaultConfig,
    procurementAnalyst: defaultConfig,
    logisticsManager: defaultConfig,
  });

  const updateConfig = (key: string, value: any) => {
    setAgentConfigs((prev) => ({
      ...prev,
      [selectedAgent]: {
        ...prev[selectedAgent],
        ...value,
      },
    }));
  };

  const currentConfig = agentConfigs[selectedAgent];

  const saveConfig = () => {
    alert('Configuration saved!');
  };

  return (
    <div className="space-y-8 max-w-4xl mx-auto">
      {/* Agent Selection */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-bold mb-4 flex items-center" style={{ color: '#004DB5' }}>
          Agent Selection
          <Tooltip content="Select an agent to configure its settings. Changes are specific to each agent.">
            <HelpCircle className="ml-2 h-5 w-5 text-gray-400" />
          </Tooltip>
        </h2>
        <select
          value={selectedAgent}
          onChange={(e) => setSelectedAgent(e.target.value)}
          className="w-full p-2 border rounded"
        >
          {agents.map(agent => (
            <option key={agent.id} value={agent.id}>
              {agent.name}
            </option>
          ))}
        </select>
      </div>

      {/* Knowledge Source Control */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-bold mb-4 flex items-center" style={{ color: '#004DB5' }}>
          Knowledge Source Control
          <Tooltip content="Determine which sources the agent can access to gather information.">
            <HelpCircle className="ml-2 h-5 w-5 text-gray-400" />
          </Tooltip>
        </h2>
        <div className="space-y-2">
          {Object.entries(currentConfig.knowledgeSources).map(([key, value]) => (
            <label key={key} className="flex items-center">
              <input
                type="checkbox"
                checked={value}
                onChange={() => updateConfig('knowledgeSources', { [key]: !value })}
                className="mr-2"
              />
              <span className="mr-2">{key === 'externalAPIs' ? 'External APIs' : key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</span>
              <Tooltip content={getKnowledgeSourceDescription(key)}>
                <Info className="h-4 w-4 text-gray-400" />
              </Tooltip>
            </label>
          ))}
        </div>
      </div>

      {/* Agent Behavior Settings */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-bold mb-4" style={{ color: '#004DB5' }}>
          Agent Behavior Settings
        </h2>
        <div className="space-y-4">
          {/* Formality */}
          <div>
            <label className="block mb-2 flex items-center">
              Formality
              <Tooltip content="Adjust how formal or casual the agent's language should be.">
                <HelpCircle className="ml-2 h-5 w-5 text-gray-400" />
              </Tooltip>
            </label>
            <input
              type="range"
              min="0"
              max="100"
              value={currentConfig.behaviorSettings.formality}
              onChange={(e) => updateConfig('behaviorSettings', { formality: Number(e.target.value) })}
              className="w-full"
            />
            <div className="flex justify-between text-sm text-gray-600">
              <span>Casual</span>
              <span>Formal</span>
            </div>
          </div>

          {/* Recommendation Style */}
          <div>
            <label className="block mb-2 flex items-center">
              Recommendation Style
              <Tooltip content="Determine how the agent presents recommendations and suggestions.">
                <HelpCircle className="ml-2 h-5 w-5 text-gray-400" />
              </Tooltip>
            </label>
            <select
              value={currentConfig.behaviorSettings.recommendationStyle}
              onChange={(e) => updateConfig('behaviorSettings', { recommendationStyle: e.target.value })}
              className="w-full p-2 border rounded"
            >
              <option value="cautious">Cautious - Emphasizes risks and limitations</option>
              <option value="balanced">Balanced - Presents pros and cons equally</option>
              <option value="proactive">Proactive - Focuses on opportunities and benefits</option>
            </select>
          </div>

          {/* Autonomy Level */}
          <div>
            <label className="block mb-2 flex items-center">
              Autonomy Level
              <Tooltip content="Set how much independence the agent has in decision-making and task execution.">
                <HelpCircle className="ml-2 h-5 w-5 text-gray-400" />
              </Tooltip>
            </label>
            <input
              type="range"
              min="0"
              max="100"
              value={currentConfig.behaviorSettings.autonomyLevel}
              onChange={(e) => updateConfig('behaviorSettings', { autonomyLevel: Number(e.target.value) })}
              className="w-full"
            />
            <div className="flex justify-between text-sm text-gray-600">
              <span>Low Autonomy</span>
              <span>High Autonomy</span>
            </div>
          </div>
        </div>
      </div>

      {/* Interaction Preferences */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-bold mb-4" style={{ color: '#004DB5' }}>
          Interaction Preferences
        </h2>
        <div className="space-y-4">
          <div>
            <label className="block mb-2 flex items-center">
              Notification Frequency
              <Tooltip content="Set how often you want to receive updates from the agent.">
                <HelpCircle className="ml-2 h-5 w-5 text-gray-400" />
              </Tooltip>
            </label>
            <select
              value={currentConfig.interactionPreferences.notificationFrequency}
              onChange={(e) => updateConfig('interactionPreferences', { notificationFrequency: e.target.value })}
              className="w-full p-2 border rounded"
            >
              <option value="minimal">Minimal - Only critical updates</option>
              <option value="moderate">Moderate - Regular progress reports</option>
              <option value="frequent">Frequent - Detailed, step-by-step updates</option>
            </select>
          </div>

          <div>
            <label className="block mb-2 flex items-center">
              Response Format
              <Tooltip content="Choose how you prefer the agent to present information to you.">
                <HelpCircle className="ml-2 h-5 w-5 text-gray-400" />
              </Tooltip>
            </label>
            <select
              value={currentConfig.interactionPreferences.responseFormat}
              onChange={(e) => updateConfig('interactionPreferences', { responseFormat: e.target.value })}
              className="w-full p-2 border rounded"
            >
              <option value="concise">Concise - Brief, to-the-point responses</option>
              <option value="detailed">Detailed - Comprehensive explanations</option>
              <option value="visual">Visual - Emphasize charts, graphs, and diagrams</option>
            </select>
          </div>
        </div>
      </div>

      {/* Learning Preferences */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-bold mb-4 flex items-center" style={{ color: '#004DB5' }}>
          Learning Preferences
          <Tooltip content="Configure how the agent learns and improves over time.">
            <HelpCircle className="ml-2 h-5 w-5 text-gray-400" />
          </Tooltip>
        </h2>
        <div className="space-y-4">
          <div>
            <label className="block mb-2">Learning Rate</label>
            <select
              value={currentConfig.learningPreferences.learningRate}
              onChange={(e) => updateConfig('learningPreferences', { learningRate: e.target.value })}
              className="w-full p-2 border rounded"
            >
              <option value="conservative">Conservative - Prioritize stability</option>
              <option value="moderate">Moderate - Balanced learning</option>
              <option value="aggressive">Aggressive - Rapid adaptation</option>
            </select>
          </div>

          <div>
            <label className="block mb-2">Focus Areas</label>
            <input
              type="text"
              placeholder="Enter focus areas separated by commas"
              value={currentConfig.learningPreferences.focusAreas.join(', ')}
              onChange={(e) => updateConfig('learningPreferences', { focusAreas: e.target.value.split(',').map(area => area.trim()) })}
              className="w-full p-2 border rounded"
            />
          </div>
        </div>
      </div>

      {/* Save Button */}
      <div className="flex justify-end">
        <button
          onClick={saveConfig}
          className="bg-[#004DB5] text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          Save Configuration
        </button>
      </div>
    </div>
  );
};

const getKnowledgeSourceDescription = (source: string) => {
  const descriptions: Record<string, string> = {
    internalDocuments: "Access and analyze company-specific documents and databases.",
    externalAPIs: "Retrieve information from authorized external data sources and services.",
    web: "Search and gather publicly available information from the internet.",
  };
  return descriptions[source] || "No description available.";
};

export default AgentConfigurationInterface;
