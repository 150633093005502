import React, { useState, useEffect } from "react";
import {
  Search,
  Upload,
  Trash2,
  FileText,
  FileImage,
  FileArchive,
  FileSpreadsheet,
  File,
  Eye,
  Edit,
} from "lucide-react";
import Tooltip from "../ui/Tooltip";

interface Document {
  id: string;
  name: string;
  type: string;
  uploadedBy: string;
  uploadDate: Date;
  size: number;
}

const mockDocuments: Document[] = [
  {
    id: "1",
    name: "Q3 Financial Report.pdf",
    type: "pdf",
    uploadedBy: "John Doe",
    uploadDate: new Date("2023-10-15"),
    size: 2500000,
  },
  {
    id: "2",
    name: "Product Roadmap 2024.docx",
    type: "docx",
    uploadedBy: "Jane Smith",
    uploadDate: new Date("2023-11-02"),
    size: 1800000,
  },
  {
    id: "3",
    name: "Customer Feedback Analysis.xlsx",
    type: "xlsx",
    uploadedBy: "Mike Johnson",
    uploadDate: new Date("2023-11-10"),
    size: 3200000,
  },
  {
    id: "4",
    name: "Company Logo.png",
    type: "png",
    uploadedBy: "Sarah Lee",
    uploadDate: new Date("2023-10-28"),
    size: 500000,
  },
  {
    id: "5",
    name: "Employee Handbook.pdf",
    type: "pdf",
    uploadedBy: "HR Department",
    uploadDate: new Date("2023-09-20"),
    size: 4100000,
  },
  {
    id: "6",
    name: "Project Alpha Presentation.pptx",
    type: "pptx",
    uploadedBy: "Tom Wilson",
    uploadDate: new Date("2023-11-15"),
    size: 5500000,
  },
  {
    id: "7",
    name: "Source Code Backup.zip",
    type: "zip",
    uploadedBy: "Dev Team",
    uploadDate: new Date("2023-11-18"),
    size: 15000000,
  },
  {
    id: "8",
    name: "Market Research Data.csv",
    type: "csv",
    uploadedBy: "Marketing Team",
    uploadDate: new Date("2023-11-05"),
    size: 2800000,
  },
  {
    id: "9",
    name: "Legal Contract Template.docx",
    type: "docx",
    uploadedBy: "Legal Department",
    uploadDate: new Date("2023-10-10"),
    size: 950000,
  },
  {
    id: "10",
    name: "Company Meeting Minutes.txt",
    type: "txt",
    uploadedBy: "Executive Assistant",
    uploadDate: new Date("2023-11-20"),
    size: 50000,
  },
];

export const DocumentList = () => {
  const [documents, setDocuments] = useState<Document[]>(mockDocuments);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState("");

  const fetchDocuments = async () => {
    try {
      // TODO: Implement actual API call
      // const response = await fetch('/document_ingestion/api/v1/knowledge/search', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ query: '' })
      // });
      // const data = await response.json();
      // setDocuments(data.results);

      // For now, use mock data
      setDocuments(mockDocuments);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const handleSearch = async (query: string) => {
    setSearchTerm(query);
    try {
      // TODO: Implement actual API call
      // const response = await fetch('/document_ingestion/api/v1/knowledge/search', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ query })
      // });
      // const data = await response.json();
      // setDocuments(data.results);

      // For now, filter mock data
      const filteredDocs = mockDocuments.filter((doc) =>
        doc.name.toLowerCase().includes(query.toLowerCase())
      );
      setDocuments(filteredDocs);
    } catch (error) {
      console.error("Error searching documents:", error);
    }
  };

  const handleUpdate = async (id: string, updatedData: Partial<Document>) => {
    try {
      // TODO: Implement actual API call
      // await fetch('/document_ingestion/api/v1/knowledge/update', {
      //   method: 'PUT',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ id, ...updatedData })
      // });

      // For now, just update local state
      setDocuments(
        documents.map((doc) =>
          doc.id === id ? { ...doc, ...updatedData } : doc
        )
      );
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      // TODO: Implement actual API call
      // await fetch('/document_ingestion/api/v1/knowledge/delete', {
      //   method: 'DELETE',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ id })
      // });

      // For now, just remove from local state
      setDocuments(documents.filter((doc) => doc.id !== id));
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const formatFileSize = (bytes: number): string => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const getFileIcon = (type: string) => {
    switch (type) {
      case "pdf":
        return <FileText className="h-5 w-5 text-[#004DB5]" />;
      case "docx":
        return <FileText className="h-5 w-5 text-[#7D7D7D]" />;
      case "xlsx":
      case "csv":
        return <FileSpreadsheet className="h-5 w-5 text-[#004DB5]" />;
      case "png":
      case "jpg":
      case "jpeg":
        return <FileImage className="h-5 w-5 text-[#7D7D7D]" />;
      case "zip":
      case "rar":
        return <FileArchive className="h-5 w-5 text-[#004DB5]" />;
      default:
        return <File className="h-5 w-5 text-gray-500" />;
    }
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="w-full table-auto">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Uploaded By
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Size
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {documents.map((doc) => (
              <tr key={doc.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    {getFileIcon(doc.type)}
                    <span className="ml-2">{doc.name}</span>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {doc.uploadedBy}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {doc.uploadDate.toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {formatFileSize(doc.size)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center space-x-2">
                    <div className="relative group">
                      <button
                        onClick={() => setSelectedDocument(doc)}
                        className="text-[#004DB5] hover:text-blue-700"
                      >
                        <Eye className="h-5 w-5" />
                      </button>
                      <div className="absolute top-full left-0 mt-2 max-w-xs bg-gray-800 text-white text-xs rounded p-2 invisible group-hover:visible z-50 shadow-lg">
                        <div className="whitespace-normal">
                          View document details
                        </div>
                      </div>
                    </div>
                    <div className="relative group">
                      <button
                        onClick={() =>
                          handleUpdate(doc.id, { name: `${doc.name} (Edited)` })
                        }
                        className="text-[#004DB5] hover:text-blue-700"
                      >
                        <Edit className="h-5 w-5" />
                      </button>
                      <div className="absolute top-full left-0 mt-2 max-w-xs bg-gray-800 text-white text-xs rounded p-2 invisible group-hover:visible z-50 shadow-lg">
                        <div className="whitespace-normal">
                          Edit document metadata. For example, you can rename
                          the file from 'Q3 Report.pdf' to 'Q3 Financial Report
                          2023.pdf' or add tags like 'financial', 'quarterly'.
                        </div>
                      </div>
                    </div>
                    <div className="relative group">
                      <button
                        onClick={() => handleDelete(doc.id)}
                        className="text-[#E53E3E] hover:text-red-700"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                      <div className="absolute top-full left-0 mt-2 max-w-xs bg-gray-800 text-white text-xs rounded p-2 invisible group-hover:visible z-50 shadow-lg">
                        <div className="whitespace-normal">
                          Permanently delete this document from the library.
                          This action cannot be undone.
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedDocument && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-lg w-full">
            <h3 className="text-lg font-semibold mb-4">
              {selectedDocument.name}
            </h3>
            <p>Type: {selectedDocument.type}</p>
            <p>Uploaded by: {selectedDocument.uploadedBy}</p>
            <p>Date: {selectedDocument.uploadDate.toLocaleDateString()}</p>
            <p>Size: {formatFileSize(selectedDocument.size)}</p>
            <button
              onClick={() => setSelectedDocument(null)}
              className="mt-4 bg-[#004DB5] text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};
