import React from 'react';
import { ChevronDown } from 'lucide-react';
import { useAgentContext } from '../../context/AgentContext';

interface AgentPersonaSelectorProps {
  isOpen: boolean;
  onToggle: () => void;
}

const AgentPersonaSelector: React.FC<AgentPersonaSelectorProps> = ({ isOpen, onToggle }) => {
  const { selectedPersona, setSelectedPersona, personas } = useAgentContext();

  const handleSelect = (persona: typeof personas[0]) => {
    setSelectedPersona(persona);
    onToggle();
  };

  return (
    <div className="relative w-64 lg:w-auto">
      <button
        onClick={onToggle}
        className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-gray-900 bg-gray-200 border border-gray-300 rounded-md shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <span>{selectedPersona.name}</span>
        <ChevronDown className="w-5 h-5 ml-2 -mr-1 text-gray-900" aria-hidden="true" />
      </button>

      {isOpen && (
        <div className="absolute right-0 z-10 w-72 mt-1 bg-white rounded-md shadow-lg">
          <ul className="py-1 overflow-auto text-base rounded-md max-h-60 focus:outline-none sm:text-sm">
            {personas.map((persona) => (
              <li
                key={persona.id}
                onClick={() => handleSelect(persona)}
                className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-blue-100"
              >
                <div className="flex items-center">
                  <span className="font-normal block truncate text-gray-900">{persona.name}</span>
                </div>
                {persona.description && (
                  <span className="text-xs text-gray-600 block mt-1 break-words">{persona.description}</span> 
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AgentPersonaSelector;